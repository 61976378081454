import React, { useContext,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import { API_SERVER } from '../../../../config/constant';

const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const [systemTitle, setSystemTitle] = useState('Synergy');
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }
    const fetchData = async () => {
        axios.get(`${API_SERVER}/settings/system/systemTitle`).then(res_title => {
            if (res_title.status === 200 && res_title.data && res_title.data.systemTitle) {
                setSystemTitle(res_title.data.systemTitle);
            }
        });
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="navbar-brand header-logo">
                <Link to="#" className="b-brand">
                    <div className="b-bg">
                        <i className="feather icon-message-circle" />
                    </div>
                    <span className="b-title">{systemTitle}</span>
                </Link>
                <Link
                    to="#"
                    className={toggleClass.join(' ')}
                    id="mobile-collapse"
                    onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
                >
                    <span />
                </Link>
            </div>
        </>
    );
};

export default NavLogo;
